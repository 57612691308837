import cn from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';

import { Button } from 'components/UIKit/Button';
import { Dropdown } from 'components/UIKit/Dropdown';

import { CreateArtLink } from '../CreateArtLink';
import { NEW_ART_BLOCKS } from './constants';

import s from './CreateArtBlock.module.scss';

export const CreateArtBlock: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const handleMenuToggle = (): void => {
    setMenuOpen(prevState => !prevState);
  };

  return (
    <Dropdown onClose={(): void => setMenuOpen(false)} isOpen={isMenuOpen}>
      <div className={s.wrapper}>
        <Button look="secondary" size="medium" onClick={handleMenuToggle}>
          Создать
        </Button>

        <ul className={cn(s.menu, { [s.menuOpen]: isMenuOpen })}>
          {NEW_ART_BLOCKS.map(({ id, label, content, link, icon }) => (
            <CreateArtLink
              key={id}
              id={id}
              label={label}
              content={content}
              link={link}
              icon={icon}
            />
          ))}
        </ul>
      </div>
    </Dropdown>
  );
};
