import cn from 'classnames';
import type { FC, ReactNode } from 'react';

import s from './Dropdown.module.scss';

interface IDropdownProps {
  onClose: () => void;
  children: ReactNode;
  isOpen: boolean;
}

export const Dropdown: FC<IDropdownProps> = ({ children, onClose, isOpen }) => {
  return (
    <div className={s.container}>
      {children}

      <div
        className={cn(s.dropdownOverlay, { [s.dropdownOverlayOpen]: isOpen })}
        onClick={onClose}
      />
    </div>
  );
};
