import { logFirebaseEvent } from 'analytics/firebase';
import {
  sendGoogleAnalytics,
  sendYandexAnalytics,
} from 'analytics/sendAnalytics';
import cn from 'classnames';
import { CreateArtBlock } from 'modules/header/components/CreateArtBlock';
import { MainMenuItems } from 'modules/header/components/MainMenuItems';
import { NotificationBlock } from 'modules/header/components/NotificationBlock';
import { ProfileMenuBlock } from 'modules/header/components/ProfileMenuBlock';
import { LitresBanner } from 'modules/litres-banner/components/LitresBanner';
import { ILitresBanner } from 'modules/litres-banner/components/LitresBanner/types';
import Head from 'next/head';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

import { pageUrls } from 'constants/pagesUrls';

import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { FunctionalButton } from 'components/UIKit/FunctionalButton';

import { openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';
import { UserInfoState } from 'sp-redux/slices/userInfo/types';

import { HeaderLogo } from './components/HeaderLogo';
import { MainMenuItemsMobile } from './components/MainMenuItemsMobile';

import s from './Header.module.scss';

interface IHeaderProps {
  userInfo?: UserInfoState;
  className?: string;
  bannerData?: ILitresBanner | null;
}

export const Header: FC<IHeaderProps> = ({
  className,
  userInfo,
  bannerData,
}) => {
  const dispatch = useDispatch();

  const isPublisher = userInfo?.is_publisher;
  const isLogged = userInfo?.isLogged || false;

  const handleButtonClick = (): void => {
    dispatch(openModal(EUiModalTypes.signIn));
    const action = 'popup_login_view';
    const source = 'login_form';
    logFirebaseEvent(action, { source });
    sendGoogleAnalytics({
      action,
    });
    sendYandexAnalytics({
      action,
      goalParams: {
        source,
        user_id: userInfo?.profile.uuid ?? '',
      },
    });
  };

  return (
    <header className={cn(s.header, className)}>
      <Head>
        <link rel="preload" href="/static/icons/logo.svg" as="image" />
      </Head>
      <LitresBanner headerBannerData={bannerData} isHeader />
      <div className={s.container}>
        {!isPublisher && (
          <div className={s.mainMenuItemsMobile}>
            <MainMenuItemsMobile isLogged={isLogged} userInfo={userInfo} />
          </div>
        )}

        {!isPublisher && (
          <ALink href="/">
            <HeaderLogo />
          </ALink>
        )}

        {isPublisher && (
          <div>
            <HeaderLogo />
          </div>
        )}

        <MainMenuItems isLogged={isLogged} userInfo={userInfo} />

        <div className={s.wrapper}>
          {/*Две кнопки войти, потому что 1) открывает модалку, 2) перенаправляет на страницу логина*/}
          {!isLogged && (
            <>
              <Button
                size="medium"
                className={s.button}
                onClick={handleButtonClick}
              >
                Войти
              </Button>
              <Button
                size="small"
                className={s.buttonMobile}
                href="/account/login/"
                target="_self"
              >
                Войти
              </Button>
            </>
          )}

          {isLogged && !isPublisher && (
            <>
              <CreateArtBlock />

              <FunctionalButton
                icon="chart24x24"
                href={pageUrls.statistics.index}
                className={s.statisticsMobile}
              />

              <NotificationBlock />

              <ProfileMenuBlock />
            </>
          )}

          {isLogged && isPublisher && <ProfileMenuBlock />}
        </div>
      </div>
    </header>
  );
};
