import Image from 'next/image';
import { FC } from 'react';

import s from './HeaderLogo.module.scss';

export const HeaderLogo: FC = () => {
  return (
    <>
      <div className={s.logoFull}>
        <Image
          src="/static/icons/logo.svg"
          aria-hidden="true"
          width="237px"
          height="22px"
        />
      </div>

      <div className={s.logoMobile}>
        <Image
          src="/static/icons/logoSmall.svg"
          aria-hidden="true"
          width="30px"
          height="30px"
        />
      </div>

      <span className="visually-hidden">Литрес:Самиздат</span>
    </>
  );
};
