import router from 'next/router';

import { EPlaceSlug } from '../types';

export const getPlaceSlug = (isMainPage?: boolean): EPlaceSlug => {
  const route = router.route;
  let placeSlug: EPlaceSlug = EPlaceSlug.NoSlug;

  if (route === '/services/promotion') {
    placeSlug = EPlaceSlug.ServicesPromo;
  }
  if (route === '/services') {
    placeSlug = EPlaceSlug.Services;
  }
  if (route.includes('/books/')) {
    placeSlug = EPlaceSlug.MyBooks;
  }
  if (
    route.includes('/services/editing') ||
    router.asPath.includes('/services/editing/order/')
  ) {
    placeSlug = EPlaceSlug.Edit;
  }
  if (
    route.includes('/services/proofread') ||
    router.asPath.includes('/services/proofread/order/')
  ) {
    placeSlug = EPlaceSlug.Proofread;
  }
  if (
    route.includes('/services/pagemaker') ||
    router.asPath.includes('/services/pagemaker/order/')
  ) {
    placeSlug = EPlaceSlug.Pagemaker;
  }
  if (
    route.includes('/services/annotation') ||
    router.asPath.includes('/services/annotation/order/')
  ) {
    placeSlug = EPlaceSlug.Annotation;
  }
  if (
    route.includes('/services/assistant') ||
    router.asPath.includes('/services/assistant/order/')
  ) {
    placeSlug = EPlaceSlug.Assistant;
  }
  if (isMainPage) {
    placeSlug = EPlaceSlug.Main;
  }

  return placeSlug;
};
