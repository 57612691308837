import { pageUrls, publisherUrls } from 'constants/pagesUrls';

import { createUTMUrl } from 'utils/createUTMUrl';

import { IMainMenuItem } from './types';

export const MAX_NOTIFICATIONS_ITEM_DROPDOWN_MENU = 4;

export const MAX_NUMBER_BADGE = 10;

export const NOT_AUTH_ITEMS: IMainMenuItem[] = [
  {
    id: 'header-status',
    name: 'Статус',
    link: pageUrls.status.index,
  },
  {
    id: 'header-services',
    name: 'Услуги',
    link: pageUrls.services.index,
  },
  {
    id: 'header-blog',
    name: 'Блог',
    link: createUTMUrl(pageUrls.blog.index, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'blog',
    }),
    target: '_blank',
  },
  {
    id: 'header-contest',
    name: 'Конкурсы',
    link: createUTMUrl(pageUrls.blog.promo, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'contest',
    }),
    target: '_blank',
  },
];

export const AUTH_ITEMS: IMainMenuItem[] = [
  {
    id: 'header-books',
    name: 'Мои книги',
    link: pageUrls.books.index,
  },
  {
    id: 'header-statistics',
    name: 'Статистика',
    link: pageUrls.statistics.index,
  },
  {
    id: 'header-payments',
    name: 'Выплаты',
    link: pageUrls.statistics.payments,
  },
  {
    id: 'header-services',
    name: 'Услуги',
    link: pageUrls.services.index,
  },
  {
    id: 'header-referral',
    name: 'Реферальная программа',
    link: pageUrls.referral.index,
  },
  {
    id: 'header-orders',
    name: 'Заказы',
    link: pageUrls.orders.index,
  },
  {
    id: 'header-blog',
    name: 'Блог',
    link: createUTMUrl(pageUrls.blog.index, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'blog',
    }),
    target: '_blank',
  },
  {
    id: 'header-ebukva2024',
    name: 'Премия 2024',
    link: createUTMUrl(pageUrls.ebukva.index, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'ebukva2024',
    }),
    target: '_blank',
  },
  {
    id: 'header-contest',
    name: 'Конкурсы',
    link: createUTMUrl(pageUrls.blog.promo, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'contest',
    }),
    target: '_blank',
  },
];

export const PUBLISHER_ITEMS: IMainMenuItem[] = [
  {
    id: 'header-statistics',
    name: 'Статистика',
    link: publisherUrls.statistics.salesRoyalty,
  },
];
