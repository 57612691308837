import cn from 'classnames';
import { AUTH_ITEMS, NOT_AUTH_ITEMS } from 'modules/header/constants';
import { IMainMenuItem } from 'modules/header/types';
import { FC, useEffect, useRef, useState } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { Dropdown } from 'components/UIKit/Dropdown';
import { FunctionalButton } from 'components/UIKit/FunctionalButton';
import { Icon } from 'components/UIKit/Icon';
import { BodyL400, BodyM400 } from 'components/basic/Typography';

import { UserInfoState } from 'sp-redux/slices/userInfo/types';

import { MainMenuItem } from '../MainMenuItem';

import s from './MainMenuItems.module.scss';

interface IMainMenuItemsProps {
  isLogged: boolean;
  userInfo?: UserInfoState;
}

export const MainMenuItems: FC<IMainMenuItemsProps> = ({
  isLogged,
  userInfo,
}) => {
  const [mainMenuItems, setMainMenuItems] = useState<IMainMenuItem[]>([]);

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isItemKicked, setItemKicked] = useState<boolean>(false);

  const [windowWidth, setWindowWidth] = useState(0);

  const handleMenuButtonToggle = (): void => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const isPublisher = userInfo?.is_publisher;

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setItemKicked(false);
    setMainMenuItems([]);
  }, [windowWidth]);

  useEffect(() => {
    const containerWidth = containerRef.current?.clientWidth ?? 0;
    const wrapperWidth = wrapperRef.current?.clientWidth ?? 0;

    if (!isLogged) {
      setMainMenuItems(NOT_AUTH_ITEMS);
    }

    if (!wrapperWidth) {
      return;
    }

    if (isLogged) {
      if (containerWidth === wrapperWidth) {
        const updatedMainMenuItems = mainMenuItems.slice(0, -1);

        setMainMenuItems(updatedMainMenuItems);
        setItemKicked(true);
      }

      if (!isItemKicked && containerWidth > wrapperWidth) {
        setMainMenuItems(prevMainMenuItems => [
          ...prevMainMenuItems,
          AUTH_ITEMS[prevMainMenuItems.length],
        ]);
      }
    }
  }, [mainMenuItems]);

  return (
    <div ref={containerRef} className={s.container}>
      <div className={s.wrapper} ref={wrapperRef}>
        {mainMenuItems.map(({ id, name, link, target }) => (
          <MainMenuItem
            key={id}
            id={id}
            name={name}
            link={link}
            target={target}
          />
        ))}

        {isLogged && !isPublisher && (
          <Dropdown
            onClose={(): void => setMenuOpen(false)}
            isOpen={isMenuOpen}
          >
            <FunctionalButton onClick={handleMenuButtonToggle}>
              <div className={s.buttonMoreBlock}>
                <BodyL400>Еще</BodyL400>
                <Icon
                  icon="chevronDownOutline16x16"
                  color="var(--palettes-gray-190"
                  className={cn(s.chevron, { [s.chevronOpen]: isMenuOpen })}
                />
              </div>
            </FunctionalButton>

            <ul className={cn(s.menu, { [s.menuOpen]: isMenuOpen })}>
              {AUTH_ITEMS.slice(mainMenuItems.length).map(
                ({ id, name, link, target }) => (
                  <li className={s.menuItem} key={id}>
                    <ALink href={link} target={target}>
                      <BodyM400>{name}</BodyM400>
                    </ALink>
                  </li>
                ),
              )}
            </ul>
          </Dropdown>
        )}
      </div>
    </div>
  );
};
