import { pageUrls } from 'constants/pagesUrls';

import { createUTMUrl } from 'utils/createUTMUrl';

import { ICreateArtLinkProps } from '../CreateArtLink';

export const NEW_ART_BLOCKS: ICreateArtLinkProps[] = [
  {
    id: 'createBook',
    label: 'Электронная книга',
    content: 'Публикация завершенной версии электронной книги',
    link: createUTMUrl(pageUrls.books.new, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'addbook',
    }),
    icon: 'addNewBook',
  },
  {
    id: 'createStory',
    label: 'Черновик',
    content: 'Публикация электронной книги отдельными главами',
    link: createUTMUrl(pageUrls.books.newDraft, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'addstory',
    }),
    icon: 'addNewStory',
  },
  {
    id: 'createAudiobook',
    label: 'Аудиокнига',
    content: 'Публикация аудиоверсии вашей электронной книги',
    link: createUTMUrl(pageUrls.audioBooks.new.master, {
      source: 'selfpub',
      medium: 'header',
      campaign: 'addaudio',
    }),
    icon: 'addNewAudio',
  },
];
