import type { FC } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { Icon } from 'components/UIKit/Icon';
import { Illustration } from 'components/basic/Illustration';
import { TIllustrationTypes } from 'components/basic/Illustration/types';
import { Caption400, Subtitle } from 'components/basic/Typography';

import s from './CreateArtLink.module.scss';

export interface ICreateArtLinkProps {
  id: string;
  label: string;
  content: string;
  link: string;
  icon: TIllustrationTypes;
}

export const CreateArtLink: FC<ICreateArtLinkProps> = ({
  label,
  content,
  link,
  icon,
}) => {
  return (
    <li className={s.item}>
      <ALink href={link} className={s.link}>
        <div className={s.iconBlock}>
          <Illustration imageType={icon} width={24} height={24} />
        </div>
        <div className={s.wrapper}>
          <Subtitle className={s.subtitle}>{label}</Subtitle>
          <Caption400 className={s.content}>{content}</Caption400>
        </div>
        <div className={s.chevronBlock}>
          <Icon icon="chevronRight" />
        </div>
      </ALink>
    </li>
  );
};
