import cn from 'classnames';
import { EProfileStatuses } from 'entities/Profile/constants';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageUrls } from 'constants/pagesUrls';

import { ALink } from 'components/UIKit/ALink';
import { Dropdown } from 'components/UIKit/Dropdown';
import { FunctionalButton } from 'components/UIKit/FunctionalButton';
import { Icon } from 'components/UIKit/Icon';
import { BodyL700, BodyM400, Caption400 } from 'components/basic/Typography';

import { TAppState } from 'sp-redux';
import {
  EAccountRoyaltyOfferStatus,
  EUserRole,
} from 'sp-redux/slices/userInfo/types';
import { logoutThunk } from 'sp-redux/thunks/userInfo/logoutThunk';

import { ProfileAvatar } from '../ProfileAvatar';
import { EProfileAvatarTypes } from '../ProfileAvatar/constants';
import { IProfileMenuItem, PROFILE_MODERATOR_ITEMS } from './constants';

import s from './ProfileMenuBlock.module.scss';

export const ProfileMenuBlock: FC = () => {
  const { userInfo, profile } = useSelector((appState: TAppState) => ({
    userInfo: appState.userInfo,
    profile: appState.profile,
  }));

  const dispatch = useDispatch();
  const isPublisher = userInfo.is_publisher;

  const PROFILE_MENU_ITEMS: IProfileMenuItem[] = [
    {
      id: 'menu-profile',
      label: 'Профиль',
      link: `${pageUrls.account.profile}${
        profile.profile.status !== EProfileStatuses.verified
          ? '?param=new_profile'
          : ''
      }`,
      icon: 'user24x24',
    },
    {
      id: 'menu-status',
      label: 'Статус',
      link: pageUrls.status.index,
      icon: 'star24x24',
    },
    {
      id: 'menu-persons',
      label: 'Мои персоны',
      link: pageUrls.persons.index,
      icon: 'mask24x24',
    },
    {
      id: 'menu-faq',
      label: 'FAQ',
      link: pageUrls.faq.index,
      icon: 'faq24x24',
    },
  ];

  const getRoles = (): string => {
    if (userInfo.profile.roles?.includes(EUserRole.mainModerators)) {
      return 'Главный модератор';
    }

    if (userInfo.profile.roles?.includes(EUserRole.moderators)) {
      return 'Модератор';
    }

    if (userInfo.profile.has_direct_agreement) {
      return 'Прямой договор';
    }

    if (isPublisher) {
      return 'Издатель';
    }

    return userInfo.profile.account_royalty_offer_status ===
      EAccountRoyaltyOfferStatus.basic
      ? 'Стартовый статус'
      : 'Продвинутый статус';
  };

  const handleLogoutClick = (): void => {
    dispatch(logoutThunk());
  };

  const isProfileRejected =
    profile.profile.status === EProfileStatuses.rejected;

  const isProfileNew = profile.profile.status === EProfileStatuses.new;

  const hasAlert = isProfileRejected || isProfileNew;

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <Dropdown onClose={(): void => setMenuOpen(false)} isOpen={isMenuOpen}>
      <div className={s.wrapper}>
        <ProfileAvatar
          type={
            userInfo.profile.has_direct_agreement
              ? EProfileAvatarTypes.secondary
              : EProfileAvatarTypes.primary
          }
          name={userInfo.profile.name}
          onClick={(): void => setMenuOpen(true)}
          hasAlert={hasAlert}
        />

        <div className={cn(s.menu, { [s.menuOpen]: isMenuOpen })}>
          <div
            className={cn(s.userBlock, {
              [s.userBlockOrange]: userInfo.profile.has_direct_agreement,
            })}
          >
            <div className={s.nameWrapper}>
              <BodyL700 className={s.name}>
                {userInfo.profile.name || userInfo.profile.email}
              </BodyL700>
              <FunctionalButton
                icon="closeOutline24x24"
                iconColor="var(--text-base)"
                onClick={(): void => setMenuOpen(false)}
                className={s.buttonClose}
              />
            </div>

            <BodyM400
              className={cn(s.role, {
                [s.roleOrange]: userInfo.profile.has_direct_agreement,
              })}
            >
              {getRoles()}
            </BodyM400>
          </div>

          {hasAlert && (
            <ALink
              className={s.alert}
              href={`${pageUrls.account.profile}?param=new_profile`}
            >
              <Icon
                icon="errorOutline24x24"
                size={24}
                color="var(--palettes-red-100)"
              />
              <Caption400 className={s.alertText}>
                {isProfileNew && 'Пожалуйста, подтвердите профиль'}
                {isProfileRejected && 'Ваш профиль возвращен на доработку'}
              </Caption400>
              <Icon
                icon="chevronRightOutline16x16"
                size={16}
                color="var(--palettes-red-100)"
                className={s.iconChevron}
              />
            </ALink>
          )}

          {userInfo.profile.roles?.includes(EUserRole.moderators) && (
            <ul className={cn(s.list, s.listModerator)}>
              {PROFILE_MODERATOR_ITEMS.map(({ id, label, link }) => (
                <li key={id}>
                  <FunctionalButton className={s.action} href={link}>
                    {label}
                  </FunctionalButton>
                </li>
              ))}
              {userInfo.profile.roles.includes(EUserRole.mainModerators) && (
                <li key="menu-final-modearation-queue">
                  <FunctionalButton
                    className={s.action}
                    href={pageUrls.moderation.queue.final}
                  >
                    На финальной модерации
                  </FunctionalButton>
                </li>
              )}
            </ul>
          )}

          {!isPublisher && (
            <ul className={s.list}>
              {PROFILE_MENU_ITEMS.map(({ id, label, link, icon }) => (
                <li key={id}>
                  <FunctionalButton
                    icon={icon}
                    iconSize={24}
                    iconPosition="left"
                    className={s.action}
                    href={link}
                  >
                    {label}
                  </FunctionalButton>
                </li>
              ))}
            </ul>
          )}

          <div className={s.logout}>
            <FunctionalButton
              icon="logoutOutline24x24"
              iconSize={24}
              iconPosition="left"
              className={s.action}
              onClick={handleLogoutClick}
            >
              Выйти
            </FunctionalButton>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};
