import type { SchemaOf } from 'yup';

interface IValidationError {
  inner: { path: string; message: string }[];
}

export async function simpleAsyncValidation<ObjectShape>(
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): Promise<IValidationError | undefined> {
  try {
    await schema.validate(object, { abortEarly: false });
  } catch (e) {
    return e as IValidationError;
  }
}

export async function oneFieldAsyncValidation<ObjectShape>(
  field: keyof ObjectShape,
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): Promise<IValidationError | undefined> {
  try {
    if (typeof field === 'string') {
      await schema.validateAt(field, object, { abortEarly: false });
    }
  } catch (e) {
    return e as IValidationError;
  }
}

export function simpleSyncValidation<ObjectShape>(
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): IValidationError | undefined {
  try {
    schema.validateSync(object, { abortEarly: false });
  } catch (e) {
    return e as IValidationError;
  }
}

export function oneFieldSyncValidation<ObjectShape>(
  field: keyof ObjectShape,
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): IValidationError | undefined {
  try {
    if (typeof field === 'string') {
      schema.validateSyncAt(field, object);
    }
  } catch (e) {
    return e as IValidationError;
  }
}
