import cn from 'classnames';
import {
  AUTH_ITEMS,
  NOT_AUTH_ITEMS,
  PUBLISHER_ITEMS,
} from 'modules/header/constants';
import { IMainMenuItem } from 'modules/header/types';
import { FC, useMemo, useState } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { Dropdown } from 'components/UIKit/Dropdown';
import { FunctionalButton } from 'components/UIKit/FunctionalButton';
import { BodyM400 } from 'components/basic/Typography';

import { UserInfoState } from 'sp-redux/slices/userInfo/types';

import s from './MainMenuItemsMobile.module.scss';

interface IMainMenuItemsMobileProps {
  isLogged: boolean;
  userInfo?: UserInfoState;
}

export const MainMenuItemsMobile: FC<IMainMenuItemsMobileProps> = ({
  isLogged,
  userInfo,
}) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const mainMenuItems = useMemo(() => {
    if (!isLogged) {
      return NOT_AUTH_ITEMS;
    }

    if (userInfo?.is_publisher) {
      return PUBLISHER_ITEMS;
    }

    return AUTH_ITEMS;
  }, [isLogged]);

  return (
    <Dropdown onClose={(): void => setMenuOpen(false)} isOpen={isMenuOpen}>
      <div className={s.wrapper}>
        <FunctionalButton
          icon="burgerMenuOutline24x24"
          iconColor="var(--palettes-gray-180"
          className={s.burger}
          onClick={(): void => setMenuOpen(true)}
        />

        <div className={cn(s.menu, { [s.menuOpen]: isMenuOpen })}>
          <div className={s.closeWrapper}>
            <FunctionalButton
              icon="closeOutline24x24"
              iconColor="var(--palettes-gray-180)"
              onClick={(): void => setMenuOpen(false)}
            />
          </div>
          <ul className={s.list}>
            {mainMenuItems.map(({ id, name, link, target }: IMainMenuItem) => (
              <li className={s.menuItem} key={id}>
                <ALink href={link} target={target}>
                  <BodyM400>{name}</BodyM400>
                </ALink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Dropdown>
  );
};
